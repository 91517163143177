<template>
  <div class="container page">
    <van-nav-bar title="填写收款卡" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">请输入您的支付宝信息</div>
      <van-cell-group>
        <van-field v-model="alipay_name" label="支付宝用户名" type="text" placeholder="请输入支付宝用户名" />
        <van-field v-model="alipay_account" label="支付宝账号" type="digit" placeholder="请输入支付宝账号" />
      </van-cell-group>
      <p>尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与账号不一致,将无法取款</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindAlipay()">确认</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:{},
      alipay_name:"",
      alipay_account:""
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindAlipay(){
      this.$http({
        method: 'post',
        data:{alipay_name:this.alipay_name,alipay_account:this.alipay_account},
        url: 'user_set_alipay'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          console.log('userinfo',res)
          this.userInfo = res.data;
          this.alipay_name = res.data.alipay_name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        console.log('bankinfo',res)
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
          if(res.data.is_alipay){
            this.is_bindalipau = true;
          }else {
            this.is_bindalipay = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
